import Vue from './base'
import router from '../router/personal'
import store from '../store'
import App from '../App.vue'

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#spa')
